<template>
	<div>
		<KTCard title="Question List">
			<template v-slot:toolbar>
				<b-button
					@click="
						(createMode = true), openModal('QuestionAnswerModal')
					"
					variant="primary"
					size="sm"
					><i class="fa fa-plus"></i> Create Question Answer
				</b-button>
			</template>

			<template v-slot:body>
				<b-row v-if="!!tableSettings">
					<b-col cols="6">
						<b-form-group class="m-2" label-size="sm">
							<template v-if="tableSettings.refresh">
								<b-button size="sm" class="mr-2">
									<i class="fa fa-sync"></i> Refresh
								</b-button>
							</template>
						</b-form-group>
					</b-col>
					<b-col cols="6">
						<b-form-group
							class="m-2"
							label-cols-sm="3"
							label-align-sm="right"
							label-size="sm"
						>
							<template v-if="tableSettings.filter">
								<b-input-group size="sm">
									<b-form-input
										v-model="filter"
										type="search"
										placeholder="Type to Search"
									></b-form-input>

									<b-input-group-append>
										<b-button
											:disabled="!filter"
											@click="filter = ''"
											>Clear</b-button
										>
									</b-input-group-append>
								</b-input-group>
							</template>
						</b-form-group>
					</b-col>
				</b-row>

				<b-table
					:per-page="tableSettings.perPage"
					:current-page="tableSettings.currentPage"
					:filter="filter"
					striped
					bordered
					show-empty
					hover
					:items="tableItems"
					:fields="fields"
				>
					<template v-slot:cell(actions)="row">
						<b-button-group size="sm">
							<b-button
								@click="onViewAnswer(row.item)"
								variant="primary"
								size="sm"
							>
								Details
							</b-button>
							<b-button
								@click="onDelete(row.item)"
								variant="danger"
								size="sm"
							>
								Delete
							</b-button>
						</b-button-group>
					</template>
				</b-table>

				<b-pagination
					v-if="tableSettings.row > tableSettings.perPage"
					class="pl-0"
					v-model="currentPage"
					:total-rows="tableSettings.rows"
					:per-page="tableSettings.perPage"
				></b-pagination>
			</template>
		</KTCard>

		<question-answer-modal
			:create-mode="createMode"
		></question-answer-modal>
	</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import {
	DELETE_QUESTION_ANSWER_OPTIONS,
	GET_QUESTION_LIST,
} from "@/core/services/store/modules/globaladmin/trainingcourse.module";

import QuestionAnswerModal from "@/view/pages/globaladmin/question/modals/QuestionAnswerModal";
import SystemHelper from "@/core/services/systemhelper.service";

export default {
	components: { QuestionAnswerModal },
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: "Global Admin" },
			{ title: "Question" },
		]);
		this.$store.dispatch(GET_QUESTION_LIST, this.defaultPayload);
	},
	computed: {
		tableSettings() {
			const list = this.$store.state.globaladmin_trainingcourse
				.questionList;
			let perPage = 20;
			let listLength;
			if (Array.isArray(list) && list.length) {
				listLength = list.length;
			} else {
				listLength = 100;
			}
			return {
				row: listLength,
				perPage: perPage,
				pagination: true,
				refresh: false,
				filter: true,
			};
		},
		tableItems() {
			const list = this.$store.state.globaladmin_trainingcourse
				.questionList;
			console.log(list);
			if (Array.isArray(list) && list.length) {
				return list;
			} else {
				return [];
			}
		},
	},
	data() {
		return {
			createMode: false,
			selectedQuestion: null,
			selectedCompany: null,
			filter: "",
			currentPage: 1,
			fields: [
				{
					key: "question_id",
				},
				{
					key: "question_type",
				},
				{
					key: "question_name",
				},
				{
					key: "actions",
					class: "text-right",
				},
			],
		};
	},

	methods: {
		onViewAnswer(rowItem) {
			this.createMode = false;
			console.log(rowItem);
			this.selectedQuestion = rowItem;
			this.openModal("QuestionAnswerModal");
		},
		onDelete(rowItem) {
			console.log(rowItem);
			this.selectedQuestion = rowItem;

			let payload = {
				question_id: rowItem.question_id,
			};
			SystemHelper.confirmationDialogHandler(
				{ title: "Confirmation", html: "Delete Question?" },
				{ action: DELETE_QUESTION_ANSWER_OPTIONS, payload: payload },
				[{ action: GET_QUESTION_LIST, payload: this.defaultPayload }],
				null
			);
		},
	},
};
</script>
