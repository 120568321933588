<template>
	<div>
		<b-row>
			<b-col>
				<base-input
					type="select"
					v-model="form.question_type"
					label="Question Type"
					placeholder="Please select"
					:options="questionTypeOptions"
					:validator="$v.form.question_type"
					:messages="localMessages"
				></base-input>
			</b-col>
			<b-col>
				<base-input
					type="number"
					v-model="form.percentage"
					label="Percentage"
					:validator="$v.form.percentage"
					:messages="localMessages"
				></base-input>
			</b-col>
			<b-col>
				<base-input
					type="text"
					v-model="form.category"
					label="Category"
					:validator="$v.form.category"
					:messages="localMessages"
				></base-input>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<base-input
					v-model="form.question_name"
					label="Question"
					:validator="$v.form.question_name"
					:messages="localMessages"
				></base-input>
			</b-col>
		</b-row>
		<b-row v-if="AnswerOptions.length">
			<b-col>
				<label>Current Answer</label>
				<div
					v-for="o in AnswerOptions"
					:key="o.id"
					class="p-4 border bg-light"
				>
					{{ o.value }}
				</div>
			</b-col>
		</b-row>
		<b-row class="mt-4">
			<b-col>
				<draggable
					:list="list"
					class="list-group"
					draggable=".item"
					@end="updateIndex"
				>
					<div
						class="item p-3 border"
						v-for="(element, index) in list"
						:key="element.index"
					>
						<b-row>
							<b-col>{{ element.value }}</b-col>
							<b-col cols="auto">
								<b-button
									@click="assignAnswer(index)"
									size="sm"
									:class="[
										{
											'btn-success':
												element.is_answer === '1',
										},
										{
											'btn-light':
												element.is_answer === '2',
										},
									]"
								>
									<i class="fa fa-check"></i>
								</b-button>
							</b-col>
						</b-row>
					</div>

					<div slot="header">
						<div class="p-3 bg-light">
							<h6>Answer Options Form</h6>
							<b-row>
								<b-col>
									<base-input
										attribute="Value"
										v-model="addAnswerForm.value"
										:validator="$v.addAnswerForm.value"
										:messages="localMessages"
									></base-input>
								</b-col>
							</b-row>
							<b-row>
								<b-col class="d-flex flex-row-reverse">
									<b-button
										:disabled="
											(form.question_type ===
												'objective' &&
												list.length === 4) ||
												(form.question_type ===
													'subjective' &&
													list.length === 1)
										"
										class="ml-3"
										size="sm"
										variant="primary"
										@click="addAnswer"
									>
										Add Answer
									</b-button>
									<b-button
										size="sm"
										variant="secondary"
										@click="resetAnswer"
									>
										Reset Answer
									</b-button>
								</b-col>
							</b-row>
						</div>

						<h6 class="pt-5">Created Answers</h6>
						<div class="p-3 bg-light" v-if="list.length === 0">
							No answer option created yet
						</div>
						<template v-if="form.question_type === 'objective'">
							<div
								class="p-3 bg-light"
								v-if="
									list.length > 0 && list.length < maxOptions
								"
							>
								You can create
								{{ maxOptions - list.length }} more option(s)
							</div>
						</template>

						<div
							class="p-3 bg-light"
							v-if="
								list.length === maxOptions &&
									!isAnswerSelected()
							"
						>
							You have to select one correct answer
						</div>
					</div>
				</draggable>
			</b-col>
		</b-row>

		<p
			v-if="form.question_type === 'objective' && list.length > 1"
			class="small"
		>
			*Change answers order by dragging it upwards or downwards
		</p>
		<b-row class="mt-6">
			<b-col class="d-flex flex-row-reverse">
				<b-button
					@click="onSubmit"
					class="ml-3"
					variant="primary"
					size="lg"
					>Submit</b-button
				>
				<b-button
					variant="secondary"
					size="lg"
					@click="closeModal('QuestionAnswerModal')"
					>Cancel</b-button
				>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import draggable from "vuedraggable";

import SystemHelper from "@/core/services/systemhelper.service";

import {
	CREATE_QUESTION_ANSWER_OPTIONS,
	GET_QUESTION_LIST,
	GET_QUESTION_ANSWER_LIST,
	UPDATE_QUESTION_ANSWER_OPTIONS,
} from "@/core/services/store/modules/globaladmin/trainingcourse.module";

export default {
	mixins: [validationMixin],
	components: { draggable },
	props: {
		createMode: { type: Boolean, default: false },
		selectedQuestion: { type: Object },
	},
	data() {
		return {
			isLoading: true,
			isEdit: false,
			modalId: "QuestionAnswerModal",
			maxOptions: 4,
			selected: "",
			isBusy: true,
			form: {
				question_type: "",
				percentage: "",
				question_name: "",
				category: "",
			},
			addAnswerForm: {
				value: "",
			},
			list: [],
			dragging: false,
		};
	},
	validations: {
		form: {
			question_type: { required },
			percentage: { required },
			question_name: { required },
			category: { required },
		},
		addAnswerForm: {
			value: { required },
		},
	},
	computed: {
		AnswerOptions() {
			if (
				this.$store.state.globaladmin_trainingcourse.questionAnswerList
			) {
				const options = this.$store.state.globaladmin_trainingcourse
					.questionAnswerList.questionAnswersList[0]
					.answerOptionsList;
				if (Array.isArray(options) && options.length) {
					return options;
				} else {
					return [];
				}
			} else {
				return [];
			}
		},
		questionTypeOptions() {
			const questionTypeList = [
				{
					name: "Objective",
					code: "objective",
				},
				{
					name: "Subjective",
					code: "subjective",
				},
			];
			let options = [];
			if (questionTypeList.length) {
				questionTypeList.forEach((questionTypeList) => {
					options.push({
						label: questionTypeList.name,
						value: questionTypeList.code,
					});
				});
			}
			return options;
		},
	},
	methods: {
		isAnswerSelected() {
			return this.list.some(function(el) {
				return el.is_answer === "1";
			});
		},
		updateIndex(idx) {
			console.log(idx);
			this.list.forEach((element, index) => {
				element.option_order = index + 1;
			});
		},
		assignAnswer(idx) {
			console.log(idx);
			this.list.map(function(x) {
				x.is_answer = "2";
				return x;
			});
			this.list[idx].is_answer = "1";
			console.log(this.list);
		},
		resetAnswer() {
			this.list = [];
		},
		addAnswer() {
			this.$v.form.$touch();
			this.$v.addAnswerForm.$touch();
			if (this.form.question_type === "objective") {
				if (!this.$v.addAnswerForm.$anyError && this.list.length < 4) {
					let x = this.list;
					x.push({
						value: this.addAnswerForm.value,
						is_answer: "2",
						option_order: x.length + 1,
						option_order_id: x.length + 1,
					});
					this.addAnswerForm.value = "";
					this.$v.addAnswerForm.$reset();
				} else {
					return;
				}
			} else if (this.form.question_type === "subjective") {
				if (
					!this.$v.addAnswerForm.$anyError &&
					this.list.length === 0
				) {
					let x = this.list;
					x.push({
						value: this.addAnswerForm.value,
						is_answer: "1",
						option_order: x.length + 1,
						option_order_id: x.length + 1,
					});
					this.addAnswerForm.value = "";
					this.$v.addAnswerForm.$reset();

					this.assignAnswer(0);
				} else {
					return;
				}
			}
		},
		onSubmit() {
			this.$v.form.$touch();

			let payload = {
				...this.form,
				answer_options_list: JSON.stringify(this.list),
			};

			if (this.$v.form.$anyError) {
				return;
			}

			if (this.list.length === 0) {
				SystemHelper.basicSwalHandler(
					"Validation Error",
					"You have to add answers option for the question",
					"danger"
				);
				return;
			}

			if (
				this.form.question_type === "objective" &&
				this.list.length < 2
			) {
				SystemHelper.basicSwalHandler(
					"Validation Error",
					"You have to add more answers option for the question",
					"danger"
				);
				return;
			}
			if (!this.isAnswerSelected()) {
				SystemHelper.basicSwalHandler(
					"Validation Error",
					"You have to select one answer for the question",
					"danger"
				);
				return;
			}

			if (!this.createMode) {
				SystemHelper.confirmationDialogHandler(
					{ title: "Confirmation", html: "Update Question?" },
					{
						action: UPDATE_QUESTION_ANSWER_OPTIONS,
						payload: payload,
					},
					[
						{
							action: GET_QUESTION_LIST,
							payload: this.defaultPayload,
						},
					],
					this.modalId
				);
			} else {
				//create
				SystemHelper.confirmationDialogHandler(
					{ title: "Confirmation", html: "Create Question?" },
					{
						action: CREATE_QUESTION_ANSWER_OPTIONS,
						payload: payload,
					},
					[
						{
							action: GET_QUESTION_LIST,
							payload: this.defaultPayload,
						},
					],
					this.modalId
				);
			}
		},
		reset() {
			this.form = {
				question_type: "",
				percentage: "",
				question_name: "",
			};

			this.addAnswerForm = {
				value: "",
			};
			this.list = [];
			this.$v.form.$reset();
			this.$v.addAnswerForm.$reset();
		},
	},
	async mounted() {
		this.isLoading = true;
		console.log("==>", this.$parent.selectedQuestion);
		let selectedQuestion = JSON.parse(
			JSON.stringify(this.selectedQuestion)
		);
		console.log("selectedQuestion: ", selectedQuestion);

		if (!this.createMode) {
			this.form = selectedQuestion;
			this.form.percentage = this.form.percentage.toString();

			let payload = {
				question_id: selectedQuestion.question_id,
			};
			await this.$store
				.dispatch(GET_QUESTION_ANSWER_LIST, payload)
				.then((res) => {
					this.isLoading = false;
					this.list =
						res.data.questionAnswersList[0].answerOptionsList;

					this.list.forEach((item) => {
						item.is_answer = item.is_answer.toString();
						this.list["answer_option_id"] = item.answer_option_id;
					});
				});
		}
		this.isLoading = false;
	},
};
</script>
