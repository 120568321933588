var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('base-input',{attrs:{"type":"select","label":"Question Type","placeholder":"Please select","options":_vm.questionTypeOptions,"validator":_vm.$v.form.question_type,"messages":_vm.localMessages},model:{value:(_vm.form.question_type),callback:function ($$v) {_vm.$set(_vm.form, "question_type", $$v)},expression:"form.question_type"}})],1),_c('b-col',[_c('base-input',{attrs:{"type":"number","label":"Percentage","validator":_vm.$v.form.percentage,"messages":_vm.localMessages},model:{value:(_vm.form.percentage),callback:function ($$v) {_vm.$set(_vm.form, "percentage", $$v)},expression:"form.percentage"}})],1),_c('b-col',[_c('base-input',{attrs:{"type":"text","label":"Category","validator":_vm.$v.form.category,"messages":_vm.localMessages},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}})],1)],1),_c('b-row',[_c('b-col',[_c('base-input',{attrs:{"label":"Question","validator":_vm.$v.form.question_name,"messages":_vm.localMessages},model:{value:(_vm.form.question_name),callback:function ($$v) {_vm.$set(_vm.form, "question_name", $$v)},expression:"form.question_name"}})],1)],1),(_vm.AnswerOptions.length)?_c('b-row',[_c('b-col',[_c('label',[_vm._v("Current Answer")]),_vm._l((_vm.AnswerOptions),function(o){return _c('div',{key:o.id,staticClass:"p-4 border bg-light"},[_vm._v(" "+_vm._s(o.value)+" ")])})],2)],1):_vm._e(),_c('b-row',{staticClass:"mt-4"},[_c('b-col',[_c('draggable',{staticClass:"list-group",attrs:{"list":_vm.list,"draggable":".item"},on:{"end":_vm.updateIndex}},[_vm._l((_vm.list),function(element,index){return _c('div',{key:element.index,staticClass:"item p-3 border"},[_c('b-row',[_c('b-col',[_vm._v(_vm._s(element.value))]),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{class:[
									{
										'btn-success':
											element.is_answer === '1',
									},
									{
										'btn-light':
											element.is_answer === '2',
									} ],attrs:{"size":"sm"},on:{"click":function($event){return _vm.assignAnswer(index)}}},[_c('i',{staticClass:"fa fa-check"})])],1)],1)],1)}),_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"p-3 bg-light"},[_c('h6',[_vm._v("Answer Options Form")]),_c('b-row',[_c('b-col',[_c('base-input',{attrs:{"attribute":"Value","validator":_vm.$v.addAnswerForm.value,"messages":_vm.localMessages},model:{value:(_vm.addAnswerForm.value),callback:function ($$v) {_vm.$set(_vm.addAnswerForm, "value", $$v)},expression:"addAnswerForm.value"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex flex-row-reverse"},[_c('b-button',{staticClass:"ml-3",attrs:{"disabled":(_vm.form.question_type ===
											'objective' &&
											_vm.list.length === 4) ||
											(_vm.form.question_type ===
												'subjective' &&
												_vm.list.length === 1),"size":"sm","variant":"primary"},on:{"click":_vm.addAnswer}},[_vm._v(" Add Answer ")]),_c('b-button',{attrs:{"size":"sm","variant":"secondary"},on:{"click":_vm.resetAnswer}},[_vm._v(" Reset Answer ")])],1)],1)],1),_c('h6',{staticClass:"pt-5"},[_vm._v("Created Answers")]),(_vm.list.length === 0)?_c('div',{staticClass:"p-3 bg-light"},[_vm._v(" No answer option created yet ")]):_vm._e(),(_vm.form.question_type === 'objective')?[(
								_vm.list.length > 0 && _vm.list.length < _vm.maxOptions
							)?_c('div',{staticClass:"p-3 bg-light"},[_vm._v(" You can create "+_vm._s(_vm.maxOptions - _vm.list.length)+" more option(s) ")]):_vm._e()]:_vm._e(),(
							_vm.list.length === _vm.maxOptions &&
								!_vm.isAnswerSelected()
						)?_c('div',{staticClass:"p-3 bg-light"},[_vm._v(" You have to select one correct answer ")]):_vm._e()],2)],2)],1)],1),(_vm.form.question_type === 'objective' && _vm.list.length > 1)?_c('p',{staticClass:"small"},[_vm._v(" *Change answers order by dragging it upwards or downwards ")]):_vm._e(),_c('b-row',{staticClass:"mt-6"},[_c('b-col',{staticClass:"d-flex flex-row-reverse"},[_c('b-button',{staticClass:"ml-3",attrs:{"variant":"primary","size":"lg"},on:{"click":_vm.onSubmit}},[_vm._v("Submit")]),_c('b-button',{attrs:{"variant":"secondary","size":"lg"},on:{"click":function($event){return _vm.closeModal('QuestionAnswerModal')}}},[_vm._v("Cancel")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }