<template>
	<b-modal
		id="QuestionAnswerModal"
		:visible="modalVisibility(modalId)"
		:title="`${textCreateMode(createMode)} Question Answer`"
		@show="onShow()"
		@ok="handleOk()"
		@hide="resetModal()"
		size="lg"
		hide-footer
		no-close-on-backdrop
		ok-only
		ok-title="Done"
	>
		<question-answer-form
			:create-mode="createdModeParse"
			:selected-question="selectedQuestion"
		></question-answer-form>
	</b-modal>
</template>

<script>
// import { GET_QUESTION_ANSWER_LIST } from "@/core/services/store/modules/globaladmin/trainingcourse.module";
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";

import QuestionAnswerForm from "@/view/pages/globaladmin/question/forms/QuestionAnswerForm.vue";

export default {
	components: { QuestionAnswerForm },
	props: {
		createMode: { type: Boolean, default: false },
	},
	data() {
		return {
			modalId: "QuestionAnswerModal",
			selectedQuestion: null,
			createdModeParse: null,
		};
	},
	methods: {
		textCreateMode(mode) {
			if (mode) {
				return "Create";
			} else {
				return "Update";
			}
		},
		onShow() {
			this.selectedQuestion = this.$parent.selectedQuestion;
			this.createdModeParse = this.createMode;
		},
		handleOk() {
			console.log("handleOk");
		},
		resetModal() {
			// this.isLoading = true;
			this.selectedQuestion = null;

			this.$store.dispatch(SET_MODAL_STATE, null);
		},
	},
};
</script>
